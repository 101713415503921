.download-notification {
  box-sizing: border-box;
  padding: 16px 24px;
  color: var(--basis-colors-text-secondary);
  font-size: 14px;
  font-variant: tabular-nums;
  font-feature-settings: "tnum";
  line-height: 1.5;
  list-style: none;
  z-index: 1010;
  width: 324px;
  max-width: calc(100vw - 32px);
  position: fixed;
  right: 24px;
  bottom: 24px;
  top: auto;
  overflow: hidden;
  background: var(--basis-colors-base-block);
  border-radius: 4px;
  box-shadow: 0 4px 12px var(--basis-colors-shadows-default);
  border: 1px solid var(--basis-colors-borders-normal);
}
.download-notification-header {
  display: inline-block;
  margin-bottom: 8px;
  color: var(--basis-colors-text-default);
  font-size: 16px;
  line-height: 24px;
}
.download-notification-content {
  font-size: 14px;
}
.download-notification-content .filename {
  width: calc(100% - 30px);
  display: inline-block;
}
.download-notification-content .controls {
  position: absolute;
  top: 0;
  right: -5px;
}
.download-notification-content .controls i {
  cursor: pointer;
}
.download-notification-content .controls i:first-child {
  margin-right: 5px;
}
.download-notification-btn {
  position: absolute;
  top: 16px;
  right: 22px;
  color: var(--basis-colors-icon-default);
  outline: none;
  cursor: pointer;
}

.notification-fade-exit, .notification-fade-enter,
.notification-fade-appear {
  animation-duration: 0.2s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}

.notification-fade-enter,
.notification-fade-appear {
  opacity: 0;
}

.notification-fade-enter.notification-fade-enter-active,
.notification-fade-appear.notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
  animation-play-state: running;
}

.notification-fade-exit.notification-fade-exit-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}

@keyframes NotificationLeftFadeIn {
  0% {
    right: -348px;
    opacity: 0;
  }
  100% {
    right: 24px;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 999px;
    bottom: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}