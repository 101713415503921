.Chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Chat .CommentsSearch {
  padding: 8px 16px;
  width: 100%;
}
.Chat-DropzoneOverlay {
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  inset: 0 0 0 0;
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Chat-NoMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.Chat .CommentList {
  flex: 0 1 100%;
  flex-direction: 0;
  overflow: auto;
  padding: 0 8px 15px;
  margin-bottom: 5px;
  position: relative;
  min-height: 400px;
}
.Chat .CommentList-Date {
  padding: 10px;
  text-transform: capitalize;
  text-align: center;
}
.Chat .ChatFooter {
  width: 100%;
  max-height: 150px;
  min-height: 150px;
  padding: 6px 6px 0 6px;
  position: relative;
}
.Chat .ChatFooter-Resizing {
  overflow: hidden;
}
.Chat .ChatFooter-MessageInput {
  display: flex;
  width: 100%;
  height: calc(100% - 20px);
  margin: 6px;
}
.Chat .ChatFooter-FilesWrapper {
  max-width: 100%;
  position: relative;
  border-radius: 4px;
}
.Chat .ChatFooter-FilesWrapper::before {
  content: "";
  position: absolute;
  background: linear-gradient(270deg, var(--basis-colors-base-block) 0%, transparent 100%);
  right: 0;
  top: 0;
  bottom: 16px;
  width: 20px;
  z-index: 5000;
}
.Chat .ChatFooter-Files {
  max-width: 100%;
  padding: 16px 16px 16px 16px;
  background: var(--basis-colors-base-block);
  border-radius: 4px;
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
  justify-items: flex-start;
}
.Chat .ChatFooter-Shadow {
  width: 100%;
  height: 13px;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.Chat .ChatFooter-Resizer {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 10px;
  border-top: 1px solid var(--basis-colors-borders-normal);
  box-shadow: 0 10px 0 var(--basis-colors-base-block);
  cursor: row-resize;
}
.Chat .ChatFooter-Resizer svg {
  transform: rotate(90deg) translateX(-6px);
}
.Chat .ChatFooter-InputWrapper {
  flex: 1;
}
.Chat .ChatFooter .ChatFooter-InputWrapper.ChatFooter-Resizing > div {
  height: calc(100% + 20px);
}
.Chat .ChatFooter .ChatFooter-InputWrapper.ChatFooter-Resizing > div .jodit-react-container {
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  position: relative;
}
.Chat .ChatFooter .ChatFooter-InputWrapper.ChatFooter-Resizing > div .jodit-react-container .jodit-workplace {
  height: calc(100% - 39px) !important;
  min-height: calc(100% - 39px) !important;
  max-height: calc(100% - 39px) !important;
}
.Chat .ChatFooter .jodit-wysiwyg {
  background-color: var(--basis-colors-base-block);
  color: var(--basis-colors-text-default);
}
.Chat .ChatFooter-SendMessageButton {
  align-self: flex-end;
}
.Chat .ChatFooter .jodit-editor__resize {
  display: none;
}
.Chat .ChatFooter .jodit-toolbar-button__trigger {
  fill: var(--basis-colors-icon-normal);
}